import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="blog-details__main">
        <div className="blog-details__content px-5">
          <h3 className="text-center">PRIVACY POLICY</h3>
          <p>
            The creator of this Privacy Policy ensures a steady commitment to
            Your privacy with regard to the protection of your invaluable
            information that you may share across this Platform. This privacy
            policy contains information about the Platform. To provide You with
            Our uninterrupted use of the Platform, We may collect and, in some
            circumstances, disclose information about you with your permission.
            To ensure better protection of Your privacy, We provide this notice
            explaining Our information collection and disclosure policies, and
            the choices You make about the way Your information is collected and
            used.
          </p>
          <p>
            ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING
            ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED
            HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS
            OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATORS OF
            THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS
            CONTAINED WITHIN IT IN ANY MANNER.
          </p>
          <h4>Information We Collect</h4>
          <p>
            We are committed to respecting Your online privacy. We further
            recognize Your need for appropriate protection and management of any
            Personal Information You share with us. We may collect the following
            information:
            <ul>
              <li>
                Personal data including but not limited to Name, Phone Number,
                Email ID, Address, City, Gender, Age, Payment Details as per
                Payment Gateway Rules
              </li>
              <li>
                Information collected through permission derived by the Platform
                for Location access, and storage permissions etc.
              </li>
              <li>
                Tracking Information such as IP address, Device ID, URL visited,
                browser information, and other interactions with the Platform
              </li>
              <li>Details of Platform usage for analytics</li>
            </ul>
          </p>
          <p>
            This privacy policy also applies to data we collect from users who
            are not registered as members of this Platform, including, but not
            limited to, browsing behaviour, pages viewed etc. We also collect
            and store personal information provided by You from time to time on
            the Platform. We only collect and use such information from you that
            we consider necessary for achieving a seamless, efficient and safe
            experience, customized to your needs including:
            <ul>
              <li>To enable the provision of services opted for by you</li>
              <li>To enable the viewing of content in your interest</li>
              <li>
                To communicate the necessary account and service-related
                information from time to time
              </li>
              <li>
                To allow you to receive quality customer care services and data
                Collection
              </li>
              <li>To comply with applicable laws, rules and regulations</li>
            </ul>
          </p>
          <p>
            Where any service requested by You involves a third party, such
            information as is reasonably necessary by the Firm to carry out Your
            service request may be shared with such third party. We also do use
            your contact information to send you offers based on your interests
            and prior activity and also to view the content preferred by you.
            The Firm may also use contact information internally to direct its
            efforts for service improvement but shall immediately delete all
            such information upon withdrawal of your consent for the same
            through the ‘unsubscribe’ button or through an email to be sent to
            “info@cigna.org.in”. <br /> To the extent possible, we provide You
            with the option of not divulging any specific information that you
            wish for us not to collect, store or use. You may also choose not to
            use a particular service or feature on the Platform and opt-out of
            any non-essential communications from the Platform.
            <br /> Further, transacting over the internet has inherent risks
            which can only be avoided by you following security practices
            yourself, such as not revealing account/login related information to
            any other person and informing our customer care team about any
            suspicious activity or where your account has/may have been
            compromised.
          </p>
          <h4>OUR USE OF YOUR INFORMATION</h4>
          <p>
            The information provided by you shall be used to provide and improve
            the service for you and all users.
            <ul>
              <li>To provide you with services on your request.</li>
              <li>For maintaining an internal record.</li>
              <li>For enhancing the Services provided.</li>
              <li>
                For maintaining record under the legal and statutory provisions.
              </li>
            </ul>
          </p>
          <p>
            For more details about the nature of such communications, please
            refer to our Terms of Service. Further, your data and Sensitive
            Personal data may be collected and stored by Us for internal record.
            <br />
            We use Your tracking information such as IP addresses, and or Device
            ID to help identify you and to gather broad demographic information
            and make further services available to you. We will not sell,
            license or trade Your personal information. We will not share your
            personal information with others unless they are acting under our
            instructions or we are required to do so by law.
            <br /> Information collected via Our server logs includes users' IP
            addresses and the pages visited; this will be used to manage the web
            system and troubleshoot problems. We also use third-party analytics,
            tracking, optimization and targeting tools to understand how users
            engage with our Platform so that we can improve it and cater
            personalized content/ads according to their preferences.
          </p>
          <h4>HOW INFORMATION IS COLLECTED</h4>
          <p>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected. If
            the same is not identified to you, you have the right to request the
            Firm to elucidate the purpose of collection of said personal
            information, pending the fulfilment of which you shall not be
            mandated to disclose any information whatsoever.
            <br /> We will collect and use your personal information solely to
            fulfil those purposes specified by us, within the scope of the
            consent of the individual concerned or as required by law. We will
            only retain personal information as long as necessary for the
            fulfilment of those purposes. We will collect personal information
            by lawful and fair means and with the knowledge and consent of the
            individual concerned.
            <br /> Personal data should be relevant to the purposes for which it
            is to be used, and, to the extent necessary for those purposes,
            should be accurate, complete, and up-to-date.
          </p>
          <h4>EXTERNAL LINKS ON THE PLATFORM</h4>
          <p>
            The Platform may include advertisements, hyperlinks to other
            Platforms or resources. We have no control over any other Platform
            or resources or contents available on these other Platforms, which
            are provided by companies or persons other than Us. You acknowledge
            and agree that we are not responsible for the availability of any
            such external sites or resources, and do not endorse any
            advertising, services/services or other materials on or available
            from such Platform or resources. You acknowledge and agree that We
            are not liable for any loss or damage which may be incurred by you
            as a result of the availability of those external sites or
            resources, or as a result of any reliance placed by you on the
            completeness, accuracy or existence of any advertising, services or
            other materials on, or available from, such Platforms. These
            external third-party Platforms and resource providers may have their
            privacy policies governing the collection, storage, retention and
            disclosure of Your Personal Information that You may be subject to.
            We recommend that you enter their Platform and review its privacy
            policy.
          </p>
          <h4>PERMISSION AND ACCESS FROM THE DEVICE</h4>
          <p>
            The Application requires permission to access the following
            features:
            <ul>
              <li>Files & Media</li>
              <li>Location</li>
              <li>Contact</li>
              <li>Notifications</li>
              <li>Phone</li>
              <li>Pop up window</li>
            </ul>
          </p>
          <h4>COOKIES</h4>
          <p>
            A cookie is a small file of letters and numbers that we store on
            your browser or the hard drive of your computer if you agree. By
            continuing to browse the site, you are agreeing to our use of
            cookies. Cookies contain information that is transferred to your
            computer’s hard drive. You can set your browser to refuse all or
            some browser cookies, or to alert you when Platforms set or access
            cookies. If you disable or refuse cookies, please note that some
            parts of this Platform may become inaccessible or not function
            properly. A list of the type of cookies we use is as follows;
            <ul>
              <li>
                <b>Strictly necessary cookies.</b> These are cookies that are
                required for the operation of our Platform. They include, for
                example, cookies that enable you to log into secure areas of our
                Platform, use a shopping cart or make use of e-billing services.
              </li>
              <li>
                <b>Analytical/performance cookies.</b> They allow us to
                recognize and count the number of visitors and to see how
                visitors move around our Platform when they are using it. This
                helps us to improve the way our Platform works, for example, by
                ensuring that users are finding what they are looking for
                easily.
              </li>
              <li>
                <b>Functionality cookies.</b> These are used to recognize you
                when you return to our Platform. This enables us to personalize
                our content for you, greet you by name and remember your
                preferences (for example, your choice of language or region).
              </li>
              <li>
                <b>Targeting cookies.</b> These cookies record your visit to our
                Platform, the pages you have visited and the links you have
                followed. We will use this information to make our Platform and
                the advertising displayed on it more relevant to your interests.
                We may also share this information with third-parties for this
                purpose.
              </li>
            </ul>
          </p>
          <p>
            Please note that third-parties (including, for example, advertising
            networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we have no
            control. These cookies are likely to be analytical/performance
            cookies or targeting cookies. You can block cookies by activating
            the setting on your browser that allows you to refuse the setting of
            all or some cookies. However, if you use your browser settings to
            block all cookies (including essential cookies) you may not be able
            to access all or parts of our Platform.
          </p>
          <h4>GOOGLE ANALYTICS</h4>
          <p>
            We use Google Analytics to help us to understand how you make use of
            our content and work out how we can make things better. These
            cookies follow your progress through us, collecting anonymous data
            on where you have come from, which pages you visit, and how long you
            spend on the site. This data is then stored by Google to create
            reports. These cookies do not store your personal data. The
            information generated by the cookie about your use of the Platform,
            including your IP address, may be transmitted to and stored by
            Google on servers in the United States. Google may use this
            information for the purpose of evaluating your use of the Platform,
            compiling reports on Platform activity for us and providing other
            services relating to Platform activity and internet usage. Google
            may also transfer this information to third parties where required
            to do so by law, or where such third parties process the information
            on Google's behalf. Google will not associate your IP address with
            any other data held by Google. By using this Platform, you consent
            to the processing of data about you by Google in the manner and for
            the purposes set out above. The Google Platform contains further
            information about Analytics and a copy of Google's privacy policy
            pages.
          </p>
          <h4>GOOGLE ADSENSE</h4>
          <p>
            Google AdSense is a tool that allows Platform publishers to deliver
            advertisements to site visitors in exchange for revenue calculated
            on a per-click or per-impression basis. To do this, Google uses
            cookies and tracking technology to deliver ads personalized to a
            Platform user/visitor. In this regard the following terms are
            specified to the Users:
            <ul>
              <li>
                Third-party vendors, including Google, use cookies to serve ads
                based on your prior visits to our Platform or other Platforms.
              </li>
              <li>
                Google's use of advertising cookies enables us and our partners
                to serve advertisements to you based on their visit to our
                Platform and/or other Platforms on the Internet.
              </li>
              <li>
                You may opt-out of personalized advertising by visiting Ads
                Settings.
              </li>
              <li>
                All advertisements of third parties on our Platform are for
                informative purposes only and neither the Platform nor the Firm
                guarantees or bears liability for the authenticity of the
                advertisements.
              </li>
              <li>
                At no point will the Firm permit its competitors to advertise on
                the Platform.
              </li>
              <li>
                You may visit the links in the advertisements at your own risk
                or choose to not accept the cookies permitting third parties to
                display their advertisements.
              </li>
            </ul>
          </p>
          <h4>YOUR RIGHTS</h4>
          <p>
            Unless subject to an exemption, you have the following rights
            concerning your data:
            <ul>
              <li>
                The right to request a copy of your data which we hold about
                you.
              </li>
              <li>
                The right to request for any correction to any personal data if
                it is found to be inaccurate or out of date.
              </li>
              <li>
                The right to withdraw Your consent to the processing at any
                time.
              </li>
              <li>The right to object to the processing of personal data.</li>
              <li>The right to complain about a supervisory authority.</li>
              <li>
                The right to obtain information as to whether personal data are
                transferred to a third country or an international organization.
              </li>
            </ul>
          </p>
          <p>
            Where you hold an account with any of our services, you are entitled
            to a copy of all personal data which we hold concerning you. You are
            also entitled to request that we restrict how we use your data in
            your account when you log in.
          </p>
          <h4>COMPLIANCES</h4>
          <h5>- Indian Compliances</h5>
          <ul>
            <li>
              This legal agreement is an electronic record in terms of the
              Indian Information Technology Act, 2000 and rules there under as
              applicable and the amended provisions about electronic records in
              various statutes as amended by the Indian Information Technology
              Act, 2000. This electronic record is generated by a computer
              system and does not require any physical or digital signatures.
            </li>
            <li>
              This legal document is published in accordance with the provisions
              of Rule 3 (1) of the Indian Information Technology (Intermediaries
              guidelines) Rules, 2011 and Rule 4 of the Information Technology
              (Reasonable security practices and procedures and sensitive
              personal data or information) Rules, 2011 of Information
              Technology Act, 2000 amended through Information Technology
              Amendment Act, 2008 that require publishing the Terms of Use and
              practices for access and usage of any functional Platform.
            </li>
          </ul>
          <h5>- GDPR Privacy Rights</h5>
          <p>
            The General Data Protection Regulation or GDPR gives certain rights
            to individuals in relation to their personal data. Accordingly, we
            are happy to offer transparency and access controls to help Users
            take advantage of those rights. As available, except as limited
            under applicable law, the rights afforded to individuals are:
          </p>
          <ul>
            <li>
              Right of access- the right to be informed of, and request access
              to, the data we process about you.
            </li>
            <li>
              Right to rectification – the right to request that we amend or
              update your data where it is inaccurate or incomplete.
            </li>
            <li>
              Right to erasure- the right to request that we delete your data.
            </li>
            <li>
              Right to restrict – the right to request that we temporarily or
              permanently stop processing all or some of your personal data.
            </li>
            <li>
              Right to object – the right to object to us processing your
              personal data on grounds relating to your particular situation.
              Along with the right to object to your personal data being
              processed for direct marketing purposes.
            </li>
            <li>
              Right to data portability – the right to request a copy of your
              personal data in electronic format and the right to transmit that
              personal data for use in another part’s service.
            </li>
            <li>
              Right not to be subject to automated decision-making, the right to
              not be subject to a decision based solely on automated decision
              making, including profiling, where the decision would have a legal
              effect on you or produce a similarly significant effect.
            </li>
          </ul>
          <h4>CONFIDENTIALITY</h4>
          <p>
            You further acknowledge that the Platform may contain information
            that is designated confidential by us and that you shall not
            disclose such information without our prior written consent. Your
            information is regarded as confidential and therefore will not be
            divulged to any third party, unless if legally required to do so to
            the appropriate authorities. We will not sell, share, or rent your
            personal information to any third party or use your e-mail address
            for unsolicited mail. Any emails sent by us will only be in
            connection with the provision of agreed services, and you retain
            sole discretion to seek for discontinuation of such communications
            at any point in time.
          </p>
          <h4>OTHER INFORMATION COLLECTORS</h4>
          <p>
            Except as otherwise expressly included in this Privacy Policy, this
            document only addresses the use and disclosure of information we
            collect from you. To the extent that you disclose your information
            to other parties, whether they are on our Platform or other sites
            throughout the Internet, different rules may apply to their use or
            disclosure of the information you disclose to them. To the extent
            that we use third party advertisers, they adhere to their privacy
            policies. Since we do not control the privacy policies of the third
            parties, you are subject to ask questions before you disclose your
            personal information to others.
          </p>
          <h4>OUR DISCLOSURE OF YOUR INFORMATION</h4>
          <p>
            We may host surveys for survey creators for our Platform who are the
            owners and users of your survey responses. We do not own or sell
            your responses. Anything you expressly disclose in your responses
            will be disclosed to survey creators. Please contact the survey
            creator directly to better understand how they might share your
            survey responses.
            <br /> Information collected will not be considered as sensitive if
            it is freely available and accessible in the public domain or is
            furnished under the Right to Information Act, 2005, any rules made
            thereunder or any other law for the time being in force.
            <br />
            Due to the existing regulatory environment, we cannot ensure that
            all of your private communications and other personally identifiable
            information will never be disclosed in ways not otherwise described
            in this Privacy Policy. By way of example (without limiting and
            foregoing), we may be forced to disclose information to the
            government, law enforcement agencies or third parties. Therefore,
            although we use industry-standard practices to protect your privacy,
            we do not promise, and you should not expect, that your personally
            identifiable information or private communications would always
            remain private. We do however assure you that any disclosure of your
            personally identifiable information shall be personally intimated to
            you through an email sent to your provided email address.
            <br /> As a matter of policy, we do not sell or rent any personally
            identifiable information about you to any third party. However, the
            following describes some of the ways that your personally
            identifiable information may be disclosed:
            <ul>
              <li>
                External Service Providers: There may be several services
                offered by external service providers that help you use our
                Platform. If you choose to use these optional services, and in
                the course of doing so, disclose information to the external
                service providers, and/or permit them to collect information
                about you, then their use of your information is governed by
                their privacy policy.
              </li>
              <li>
                Law and Order: We cooperate with law enforcement inquiries, as
                well as other third parties to enforce laws, such as
                intellectual property rights, fraud and other rights. We can
                (and you authorize us to) disclose any information about you to
                law enforcement and other government officials as we, in our
                sole discretion, believe necessary or appropriate, in connection
                with an investigation of fraud, intellectual property
                infringements, or other activity that is illegal or may expose
                us or you to legal liability.
              </li>
            </ul>
          </p>
          <h4>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</h4>
          <p>
            You can review and change the information you submitted except Email
            ID. An option for facilitating such change shall be present on the
            Platform and such change shall be facilitated by the User. If you
            change any information, we may or may not keep track of your old
            information. We will not retain in our files information you have
            requested to remove for certain circumstances, such as to resolve
            disputes, troubleshoot problems and enforce our terms and
            conditions. Such prior information shall be completely removed from
            our databases, including stored ‘back up’ systems. If you believe
            that any information, we are holding on to you is incorrect or
            incomplete, or to remove your profile so that others cannot view it,
            the User needs to remediate, and promptly correct any such incorrect
            information.
          </p>
          <h4>SECURITY</h4>
          <p>
            We treat data as an asset that must be protected against loss and
            unauthorized access. We employ many different security techniques to
            protect such data from unauthorized access by members inside and
            outside the Firm. We follow generally accepted industry standards to
            protect the Personal Information submitted to us and information
            that we have accessed. However, as effective as encryption
            technology is, no security system is impenetrable. Our Firm cannot
            guarantee the security of our database, nor can we guarantee that
            information you provide won’t be intercepted while being transmitted
            to the Firm over the Internet.
          </p>
          <h4>SEVERABILITY</h4>
          <p>
            Each paragraph of this Privacy Policy shall be and remain separate
            from and independent of and severable from all and any other
            paragraphs herein except where otherwise expressly indicated or
            indicated by the context of the agreement. The decision or
            declaration that one or more of the paragraphs are null and void
            shall not affect the remaining paragraphs of this privacy policy.
          </p>
          <h4>AMENDMENT</h4>
          <p>
            Our Privacy Policy may change from time to time. The most current
            version of the policy will govern our use of your information and
            will always be on the Platform. Any amendments to this Policy shall
            be deemed as accepted by the User on their continued use of the
            Platform.
          </p>
          <h4>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</h4>
          <p>
            To withdraw your consent, or to request the download or delete your
            data with us for any or all our services at any time, please email
            to “info@cigna.org.in”.
          </p>
          <h4>CONTACT US</h4>
          <p>
            If you have any questions or concerns regarding this privacy policy,
            you should contact us by sending an e-mail to “info@cigna.org.in”.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
